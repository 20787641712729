import React from "react";
import { SvgIcon } from "@material-ui/core";

const faPadding = 96;

export default function importFaIcon({ icon: [viewBoxW, viewBoxH, , , path] }) {
  const padLeft = (512 - viewBoxW) / 2;
  const padTop = (512 - viewBoxH) / 2;
  return ({ titleAccess }) => (
    <SvgIcon
      viewBox={`${0 - padLeft - faPadding} ${0 - padTop - faPadding} ${
        512 + faPadding * 2
      } ${512 + faPadding * 2}`}
      titleAccess={titleAccess}
    >
      <path d={path} />
    </SvgIcon>
  );
}
