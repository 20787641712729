// src/ui/theme/index.js

import { createMuiTheme } from "@material-ui/core/styles";

const palette = {
  primary: { main: "#6D4C41", contrastText: "#ffffff" },
  secondary: { main: "#546E7A", contrastText: "#000000" },
  background: {
    paper: "#fafafa",
    default: "#546E7A",
  },
};
const themeName = "Kabul Silver Kangaroo Rat";

export default createMuiTheme({ palette, themeName });
